import { useAxiosTEQ } from "@/services/axios";
import { HTTP_ORDER } from "@/services/enums/httpEnum";
import {
  CreateDirectOrderResponse,
  OrderDetailsPayload,
  OrderDetailsResponse,
  PayloadDirectOrderPost,
  UploadAttachmentResponse,
} from "@/stores/direct-order/types";

import { AxiosResponse } from "axios";

/**
 * create order quotation
 * @param {NewOrder} payload for trip Filter
 * @param {string} bus_company - bus company
 *  @returns {Promise<AxiosResponse<CreateOrderResponse>>} - axios response
 */
const createDirectOrder = (
  payload: PayloadDirectOrderPost,
  bus_company: string
): Promise<AxiosResponse<CreateDirectOrderResponse>> => {
  return useAxiosTEQ.post(`${HTTP_ORDER.CREATE_MULTIPLE_TRIP_ORDER}`, payload, {
    params: {
      bus_company,
      org_ids: JSON.stringify([payload.org_id.toString()]),
    },
  });
};

const fetchOrderDetails = (
  payload: OrderDetailsPayload
): Promise<AxiosResponse<OrderDetailsResponse>> => {
  return useAxiosTEQ.get(
    `${HTTP_ORDER.FETCH_ORDER_DETAILS}/${payload.order_id}`,
    {
      params: {
        bus_company: payload.bus_company,
        org_ids: JSON.stringify([payload.org_id.toString()]),
      },
    }
  );
};

/**
 * create order quotation
 * @param {object} payload for trip Filter
 * @param payload.org_id
 * @param payload.bus_company
 * @param {string} bus_company - bus company
 * @param payload.file
 * @param payload.file_path_list
 *  @returns {Promise<AxiosResponse<UploadAttachmentResponse>>} - axios response
 */
const uploadTripAttachments = (payload: {
  bus_company: string;
  org_id: string;
  file_path_list: string[];
}): Promise<AxiosResponse<UploadAttachmentResponse>> => {
  const reqBody = {
    file_path_list: payload.file_path_list,
  };
  return useAxiosTEQ.post(`${HTTP_ORDER.UPLOAD_TRIP_ATTACHMENT}`, reqBody, {
    params: {
      bus_company: payload.bus_company,
      org_ids: JSON.stringify([payload.org_id.toString()]),
    },
  });
};

export { createDirectOrder, fetchOrderDetails, uploadTripAttachments };
