import {
  RoutePoints,
  TViaPoints,
} from "@/components/ViaPointsPackage/types/types";
import { REPEAT_END_TYPE, REPEAT_TYPE } from "@/services/enums/commonEnum";
import {
  DirectOrderTrip,
  DIRECTS_ORDER_FIELDS,
  RepeatTripConfig,
} from "@/stores/direct-order/types";
import { checkUTCorNot } from "@/utils/dateFormat.util";
import {
  findWeekdaysInOccurrence,
  findWeekdaysInRange,
  formatDateTime,
  isBeforeOrEqual,
} from "@/utils/time.utils";
import { generateId } from "@/utils/utils";
import { addDays, addMilliseconds } from "date-fns";
import { cloneDeep, isEmpty } from "lodash";

/**
 * Updates the planned arrival and departure times in the viaPoints array based on a new trip departure date.
 * @param {TViaPoints[]} viaPoints - Array of via points containing the planned arrival and departure times.
 * @param {Date} newTripDepartureDate - The new departure date for the trip.
 * @param diffWithOriginalTrip
 * @returns {TViaPoints[]} Updated array of via points with adjusted times.
 */
const updateDatesInPointsArray = (
  viaPoints: TViaPoints[],
  diffWithOriginalTrip: number
) => {
  for (const point of viaPoints) {
    point.planned_departure_time = new Date(
      new Date(
        checkUTCorNot(point.planned_departure_time as string)
      ).valueOf() + diffWithOriginalTrip
    );

    point.planned_arrival_time = point.planned_arrival_time
      ? new Date(
          new Date(
            checkUTCorNot(point.planned_arrival_time as string)
          ).valueOf() + diffWithOriginalTrip
        )
      : null;

    point.actual_departure_time = !isEmpty(point.actual_departure_time)
      ? new Date(
          new Date(
            checkUTCorNot(point.actual_departure_time as string)
          ).valueOf() + diffWithOriginalTrip
        )
      : null;
  }

  return viaPoints;
};

/**
 * Updates the planned arrival and departure times for the oneway and return trips in the routePoints object.
 * @param {RoutePoints} routePoints - The route points object containing oneway and return trip points.
 * @param {Date} newTripDepartureDate - The new departure date for the trip.
 * @param diffWithOriginalTrip
 * @param {boolean} busAvailability - Flag indicating if a bus is available for the return trip.
 * @returns {RoutePoints} The updated route points with adjusted times.
 */
const updateRoutePointDates = (
  routePoints: RoutePoints,
  diffWithOriginalTrip: number
): RoutePoints => {
  const tempUpdatedRoutePoints = cloneDeep(routePoints);
  const updatedRoutePoints = {
    oneway: updateDatesInPointsArray(
      tempUpdatedRoutePoints.oneway,
      diffWithOriginalTrip
    ),
    return:
      routePoints.return.length > 0
        ? updateDatesInPointsArray(
            tempUpdatedRoutePoints.return,
            diffWithOriginalTrip
          )
        : [],
  };
  return updatedRoutePoints;
};

/**
 * Generates daywise repeated trips based on the given configuration.
 * It creates new trips by updating the departure and arrival times
 * according to the repeat configuration, and either stops after a
 * certain end date or a set number of occurrences.
 * @param {DirectOrderTrip} trip - The original trip object to be repeated.
 * @param {RepeatTripConfig} repeatConfig - Configuration specifying
 *  the repeat criteria (start date, repeat interval, end condition, etc.).
 * @returns {Object.<string, DirectOrderTrip>} An object where the keys are
 *  new trip IDs, and the values are the newly generated repeated trips.
 */
const executeDaywiseRepeatTrips = (
  trip: DirectOrderTrip,
  repeatConfig: RepeatTripConfig
) => {
  //implement days logic
  const { startDate, dayOrWeekCount, endDate, occurence, selectedEndOption } =
    repeatConfig;

  let repeatTrips = {};

  // generated new trip departure date without timezone conversion. Ensure that the datetime
  // doesn't contain T and Z.
  let newTripDepartureDate = new Date(
    `${startDate.toDateString()} ${new Date(
      checkUTCorNot(trip[DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME] as string)
    ).toTimeString()}`
  );

  //getting difference between the new trip departure and target trip in miliseconds.
  let diffWithOriginalTrip =
    newTripDepartureDate.valueOf() -
    new Date(
      checkUTCorNot(trip[DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME] as string)
    ).valueOf();

  if (selectedEndOption === REPEAT_END_TYPE.ON_END_DATE) {
    while (isBeforeOrEqual(newTripDepartureDate, endDate)) {
      const updatedRoutePoints = updateRoutePointDates(
        trip[DIRECTS_ORDER_FIELDS.ROUTE_POINTS],
        diffWithOriginalTrip
      );

      const newTrip: DirectOrderTrip = {
        ...trip,
        [DIRECTS_ORDER_FIELDS.GEN_TID]: generateId(),
        [DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]: formatDateTime(
          newTripDepartureDate,
          "yyyy-MM-dd HH:mm"
        ),
        [DIRECTS_ORDER_FIELDS.PRICE]: 0,
        [DIRECTS_ORDER_FIELDS.PRICE_EX_VAT]: 0,
        [DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME]: addMilliseconds(
          new Date(
            checkUTCorNot(trip[DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME] as string)
          ),
          diffWithOriginalTrip
        ).toString(),
        [DIRECTS_ORDER_FIELDS.ROUTE_POINTS]: updatedRoutePoints,
      };

      delete newTrip[DIRECTS_ORDER_FIELDS.ID];

      repeatTrips = {
        ...repeatTrips,
        [newTrip[DIRECTS_ORDER_FIELDS.GEN_TID]]: newTrip,
      };

      newTripDepartureDate = addDays(newTripDepartureDate, dayOrWeekCount);

      diffWithOriginalTrip =
        newTripDepartureDate.valueOf() -
        new Date(
          checkUTCorNot(trip[DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME] as string)
        ).valueOf();
    }
  } else if (selectedEndOption === REPEAT_END_TYPE.AFTER_OCCURENCE) {
    let occCount = 1;

    while (occCount <= occurence) {
      const updatedRoutePoints = updateRoutePointDates(
        trip[DIRECTS_ORDER_FIELDS.ROUTE_POINTS],
        diffWithOriginalTrip
      );

      const newTrip: DirectOrderTrip = {
        ...trip,
        [DIRECTS_ORDER_FIELDS.GEN_TID]: generateId(),
        [DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]: formatDateTime(
          newTripDepartureDate,
          "yyyy-MM-dd HH:mm"
        ),
        [DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME]: addMilliseconds(
          new Date(
            checkUTCorNot(trip[DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME] as string)
          ),
          diffWithOriginalTrip
        ).toString(),
        [DIRECTS_ORDER_FIELDS.ROUTE_POINTS]: updatedRoutePoints,
      };

      delete newTrip[DIRECTS_ORDER_FIELDS.ID];

      repeatTrips = {
        ...repeatTrips,
        [newTrip[DIRECTS_ORDER_FIELDS.GEN_TID]]: newTrip,
      };

      newTripDepartureDate = addDays(newTripDepartureDate, dayOrWeekCount);

      diffWithOriginalTrip =
        newTripDepartureDate.valueOf() -
        new Date(
          checkUTCorNot(trip[DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME] as string)
        ).valueOf();

      occCount++;
    }
  }
  return repeatTrips;
};

/**
 * Generates repeated trips based on the weekly repetition configuration provided.
 * This function supports generating trips either up to a certain end date or based on a set occurrence count.
 * The new trips are created by adjusting the dates of the original trip while maintaining other properties.
 * @param {DirectOrderTrip} trip - The original trip object that serves as the template for creating repeated trips.
 * @param {RepeatTripConfig} repeatConfig - The configuration object containing information on how the trips should be repeated.
 * @returns {Record<string, DirectOrderTrip>} A record of newly created trips, where each trip ID maps to a DirectOrderTrip object.
 */
const executeWeeklyRepeatTrips = (
  trip: DirectOrderTrip,
  repeatConfig: RepeatTripConfig
) => {
  //implement weeks logic

  const {
    startDate,
    dayOrWeekCount,
    endDate,
    selectedDays,
    occurence,
    selectedEndOption,
  } = repeatConfig;

  let repeatTrips = {};

  if (selectedEndOption === REPEAT_END_TYPE.ON_END_DATE) {
    const dayDates = findWeekdaysInRange(
      startDate,
      endDate,
      selectedDays,
      dayOrWeekCount
    );

    dayDates.forEach((currentDate) => {
      const currentDateWithUpdatedTime = new Date(
        `${currentDate.toDateString()} ${new Date(
          checkUTCorNot(trip[DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME] as string)
        ).toTimeString()}`
      );

      const diffWithOriginalTrip =
        currentDateWithUpdatedTime.valueOf() -
        new Date(
          checkUTCorNot(trip[DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME] as string)
        ).valueOf();

      const updatedRoutePoints = updateRoutePointDates(
        trip[DIRECTS_ORDER_FIELDS.ROUTE_POINTS],
        diffWithOriginalTrip
      );
      const newTrip: DirectOrderTrip = {
        ...trip,
        [DIRECTS_ORDER_FIELDS.GEN_TID]: generateId(),
        [DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]: formatDateTime(
          currentDateWithUpdatedTime,
          "yyyy-MM-dd HH:mm"
        ),
        [DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME]: addMilliseconds(
          new Date(
            checkUTCorNot(trip[DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME] as string)
          ),
          diffWithOriginalTrip
        ).toString(),
        [DIRECTS_ORDER_FIELDS.ROUTE_POINTS]: updatedRoutePoints,
      };

      delete newTrip[DIRECTS_ORDER_FIELDS.ID];

      repeatTrips = {
        ...repeatTrips,
        [newTrip[DIRECTS_ORDER_FIELDS.GEN_TID]]: newTrip,
      };
    });
  } else {
    const dayDates = findWeekdaysInOccurrence(
      startDate,
      selectedDays,
      occurence,
      dayOrWeekCount
    );

    dayDates.forEach((currentDate) => {
      const currentDateWithUpdatedTime = new Date(
        `${currentDate.toDateString()} ${new Date(
          checkUTCorNot(trip[DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME] as string)
        ).toTimeString()}`
      );

      const diffWithOriginalTrip =
        currentDateWithUpdatedTime.valueOf() -
        new Date(
          checkUTCorNot(trip[DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME] as string)
        ).valueOf();

      const updatedRoutePoints = updateRoutePointDates(
        trip[DIRECTS_ORDER_FIELDS.ROUTE_POINTS],
        diffWithOriginalTrip
      );
      const newTrip: DirectOrderTrip = {
        ...trip,
        [DIRECTS_ORDER_FIELDS.GEN_TID]: generateId(),
        [DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]: formatDateTime(
          currentDateWithUpdatedTime,
          "yyyy-MM-dd HH:mm"
        ),
        [DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME]: addMilliseconds(
          new Date(
            checkUTCorNot(trip[DIRECTS_ORDER_FIELDS.ARRIVAL_DATETIME] as string)
          ),
          diffWithOriginalTrip
        ).toString(),
        [DIRECTS_ORDER_FIELDS.ROUTE_POINTS]: updatedRoutePoints,
      };

      delete newTrip[DIRECTS_ORDER_FIELDS.ID];

      repeatTrips = {
        ...repeatTrips,
        [newTrip[DIRECTS_ORDER_FIELDS.GEN_TID]]: newTrip,
      };
    });
  }

  return repeatTrips;
};

/**
 * Generates repeat trips based on a specified configuration.
 *
 * This function creates new trips by repeating an existing trip according to the
 * repeat configuration, which includes options for repeating by day or week, ending
 * on a specific date, or after a certain number of occurrences.
 * @param {DirectOrderTrip} trip - The original trip object containing details such as departure, arrival times, and route points.
 * @param {RepeatTripConfig} repeatConfig - Configuration object specifying the repetition criteria.
 * @param {Date} repeatConfig.startDate - The start date for repeating trips.
 * @param {number} repeatConfig.dayOrWeekCount - Number of days or weeks between each repeated trip.
 * @param {string} repeatConfig.dayOrWeek - The type of repetition: either "DAYWISE" or "WEEKLY".
 * @param {Array<number>} repeatConfig.selectedDays - The selected days of the week for weekly repetitions.
 * @param {Date} repeatConfig.endDate - The end date to stop repeating trips.
 * @param {number} repeatConfig.occurence - The number of times the trip should repeat.
 * @param {string} repeatConfig.selectedEndOption - The end condition: "ON_END_DATE" or "AFTER_OCCURENCE".
 * @returns {object} - A collection of repeated trips keyed by their IDs, or null if no trips are created.
 */
export const executeRepeatTrip = (
  trip: DirectOrderTrip,
  repeatConfig: RepeatTripConfig
) => {
  const { dayOrWeekCount, dayOrWeek } = repeatConfig;

  let repeatTrips = null;

  if (dayOrWeekCount > 0) {
    if (dayOrWeek === REPEAT_TYPE.DAYWISE) {
      repeatTrips = executeDaywiseRepeatTrips(trip, repeatConfig);
    }
    if (dayOrWeek === REPEAT_TYPE.WEEKLY) {
      repeatTrips = executeWeeklyRepeatTrips(trip, repeatConfig);
    }
  }
  return repeatTrips;
};

/**
 * The function calculates the number of repeated trips based on the provided trip and repeat
 * configuration.
 * @param {DirectOrderTrip} trip - The `trip` parameter represents a DirectOrderTrip object, which
 * likely contains information about a specific trip such as departure date and time.
 * @param {RepeatTripConfig} repeatConfig - The `repeatConfig` parameter contains the following
 * properties:
 * @returns {number} The function `getTripCountForDaywiseRepeatTrips` returns the count of repeated trips based
 * on the provided `trip` and `repeatConfig` parameters.
 */
export const getTripCountForDaywiseRepeatTrips = (
  trip: DirectOrderTrip,
  repeatConfig: RepeatTripConfig
): number => {
  const { startDate, dayOrWeekCount, endDate, occurence, selectedEndOption } =
    repeatConfig;

  let count = 0;

  let newTripDepartureDate = new Date(
    checkUTCorNot(
      `${startDate.toDateString()} ${new Date(
        trip[DIRECTS_ORDER_FIELDS.DEPARTURE_DATETIME]
      ).toTimeString()}`
    )
  );

  if (selectedEndOption === REPEAT_END_TYPE.ON_END_DATE) {
    while (isBeforeOrEqual(newTripDepartureDate, endDate)) {
      newTripDepartureDate = addDays(newTripDepartureDate, dayOrWeekCount);
      count++;
    }
  } else if (selectedEndOption === REPEAT_END_TYPE.AFTER_OCCURENCE) {
    while (count < occurence) {
      newTripDepartureDate = addDays(newTripDepartureDate, dayOrWeekCount);
      count++;
    }
  }
  return count;
};

/**
 * The function `getTripCountForWeeklyRepeatTrips` calculates the number of trips based on a weekly
 * repeat configuration.
 * @param {DirectOrderTrip} trip - The `trip` parameter is a DirectOrderTrip object, which likely
 * contains information about a specific trip or order.
 * @param {RepeatTripConfig} repeatConfig - The `repeatConfig` parameter contains the configuration
 * settings for a repeated trip. It includes the following properties:
 * @returns {number} The function `getTripCountForWeeklyRepeatTrips` returns the number of weekdays within the
 * specified range or occurrence based on the provided repeat configuration for a given trip.
 */
export const getTripCountForWeeklyRepeatTrips = (
  trip: DirectOrderTrip,
  repeatConfig: RepeatTripConfig
): number => {
  const {
    startDate,
    dayOrWeekCount,
    endDate,
    selectedDays,
    occurence,
    selectedEndOption,
  } = repeatConfig;

  let dayDates = [];

  if (selectedEndOption === REPEAT_END_TYPE.ON_END_DATE) {
    dayDates = findWeekdaysInRange(
      startDate,
      endDate,
      selectedDays,
      dayOrWeekCount
    );
  } else {
    dayDates = findWeekdaysInOccurrence(
      startDate,
      selectedDays,
      occurence,
      dayOrWeekCount
    );
  }
  return dayDates.length;
};
